@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Teko);
* {
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .25px;
    background-color: #000;
    color: #fff;
    overflow-y: hidden;
}

canvas {
    display: block;
    cursor: pointer !important;
}



/* Accueil block
=========================== */

h1 a {
    padding-left: 45px;
    color: #D19A66;
}

h2 a {
    padding-right: 45px;
    color: #8F8F8F;

}

h2 a:hover, h2 a:focus {
    color: #D19A66;
    cursor: pointer;
}

.item {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .25px;
    margin-top: 40px;
}

#navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#work_name {
    display: block;
    margin-top: 55px;
}

p {
    display: block;
}

#work_name p {
    padding-left: 45px;
    padding-right: 45px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    color: #DEA36D;
}

#work_name p:nth-child(2) {
    margin-top: 10px;
    color: #8F8F8F;
    /* color: #FDFDEB; */

    font-size: 1.5rem;
}

.about_work {
    color: #fff !important;
}

.slide {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    margin-top: -350px !important;
}

.blocHorizontal {
    display: flex;
    flex-direction: row;
    width: 50%;
}

#container-mobile {
    display: none;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    margin-top: 100px !important;
    /* pour ie et mozilla */
    -ms-overlow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    display: none;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.one {
    background-image: url(/static/media/ball.357d05f1.webp);
    background-repeat: no-repeat;
    background-position: center;
}

.two {
    background-image: url(/static/media/earth.b99d0d23.webp);
    background-repeat: no-repeat;
    background-position: center;
}

.three {
    background-image: url(/static/media/google-maps.5478913f.webp);
    background-repeat: no-repeat;
    background-position: center;
}

.four {
    background-image: url(/static/media/people.6b34164c.webp);
    background-repeat: no-repeat;
    background-position: center;
}

a {
    text-decoration: none;
    color: #fff;
}

/* OVERLAY
=========================== */

/* .curve {
    color: white;
    position: relative;

    width: 100%;
    z-index: 100;
  }
  .curve::before {
    background-color:  rgb(223, 211, 195) !important;

      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      border-top-left-radius: 50vw;
      border-top-right-radius: 50vw;
      width: 140vw;
      min-height: -webkit-fill-available;
      z-index: 100;
    }
@media  screen and (max-width: 1025px) {
    .curve::before {
        width: 100vw !important;
    }

} 
#cover {
    position: absolute;
    width: 300%;
    height: 300%;
    z-index: 99;
    background: rgb(160,82,45);
  } */

/* Project
=========================== */

#project {
    height: 85vh;
}

#container_main {
    position: absolute;
    top: 30%;
    width: 100%;
}

.container_1 {
    width: 100% !important;
}

.container_2 {
    width: 70%;
}

.container_2_bloc {
    text-align: center;
}

.container_1_bloc {
    width: 100%;
    z-index: 100;
}

.container_1_bloc:before {
    content: '';
    position: absolute;
    top: 100;
    right: 0;
    border-top: 60px solid #000;
    border-right: 100vw solid transparent;
    width: 0;
}

.container_top {
    width: 100%;
}

.project_name, .project_detail, .project_label {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.project_label:nth-child(1) {
    margin-bottom: 60px;
    ;
}

.label_1 {
    width: 35%;
}

.label_2{
    width: 65%;
}

.label_next {
    width: 25%;
}

.label_next:nth-child(1) {
    width: 35% !important;
}

.label_next {
    width: 20%;
}

.details {
    font-family: "Raleway", "sans-serif";
    padding-left: 50px;
    padding-bottom: 50px;
    padding-top: 10px !important;
    font-weight: 400;
    font-size: 25px;
    letter-spacing: .25px;
    color: #8F8F8F;
}


.horsligne p, .horsligne span {
    color: #4a4a4a !important;
}

.horsligne p, .horsligne span:hover {
    color: #4a4a4a !important;
}

.details .link span {
    color: #DEA36D;
}

.details .link span:hover {
    color: #fff
}

#live .details:hover {
    color: #DEA36D;
}

.details_label {
    padding-left: 50px !important;
    padding-right: 50px !important;
    /* color: #4a4a4a; */
    color: #DEA36D;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: .25px;
}

.details:nth-child(2){
    padding-right: 50px !important;
}

/* Parallax bloc image
=========================== */

.block_image_flex img {
    width: 100%;
}

.block_image_flex {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bloc_4 {
    width: 100%;
}

.item_bloc_2 {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    z-index: 100 !important;
}

.opac {
    opacity: 0.69;
    z-index: 1 !important;
}

.item_flex_2 img {
    width: 70% !important;
}

/* A propos
=========================== */

#container_top_about {
    position: absolute;
    width: 100%;
    z-index: 1;
}

#container_about {
    display: flex;
    height: 100vh;
    /* justify-content: center; */
}

.item_about {
    width: 50%;
    flex-grow: 1;
}

.item_about:nth-child(1) {
    /* The image used */
    background-image: url(/static/media/profil6.2d7dac7e.webp);
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.item_about img {
    width: 100vh;
}

.custom::before {
    content: '';
    position: absolute;
    top: 100;
    right: 50%;
    border-left: 200px solid transparent;
    border-bottom: 100vh solid black;
    width: 0;
}

.item_about:nth-child(2) {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 23px;
    margin-top: 105px;
    margin-left: 30px;
    overflow: auto;
}

.last {
    padding-top: 3rem;
    padding-bottom: 8rem;
}

.compagny_work ul, .contact_list ul, .skill_about {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    justify-content: space-between;
    padding-right: 50px;
}

.compagny_work li, .contact_list li {
    line-height: 1rem !important;
    width: 25%;
    font-size: 1rem;
    line-height: 2rem;
    color: #fff;
    text-align: left !important;
}

.compagny_work li {
    margin-top: 1.5rem;
}

.contact_list li {
    margin-top: 0.5rem;
}

.skill_about ul {
    list-style-type: none;
}

.flex_li li {
    line-height: 1rem !important;
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1.5rem;
    color: #8F8F8F;
}

.skill_about li {
    text-align: left !important;
}

.skill_about li span {
    line-height: 1rem !important;
    font-size: 1rem;
    line-height: 2rem;
    color: #fff;
}

.flex_ul {
    width: 25%;
}

.title_about {
    font-family: 'Teko', sans-serif;
    font-size: 2rem;
    margin-top: 4.5rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: #DEA36D;
    text-align: left !important;
}

.title_about:nth-child(1) {
    margin-top: 0;
}

.texte_about {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1.5rem;
    color: #fff;
    text-align: left !important;
    padding-right: 45px;
}

.compagny_work a:hover, .contact_list a:hover {
    border-bottom: 1px solid #DEA36D;
    padding-bottom: 2px;
    color: #DEA36D;
}


/* ----------------------------
	NotFound
---------------------------- */

.notFound {
	position: absolute;
	top:50%;
	left:50%;
	text-align: center;
    font-size: 100px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	color: #fff;
    font-family: 'Teko', sans-serif;
}

@media (max-width: 440px) {
    .notFound {
        font-size: 60px;
    }
}

@media (max-width: 1900px) {
    /* CSS */
    .item_flex_1 {
        width: 50%;
        align-items: center;
    }
    .item_flex_2 {
        width: 100%;
        display: block !important;
        align-items: center;
    }
}

@media (max-width: 1380px) {
    .bloc_1, .bloc_2, .bloc_3 {
        margin-top: 50px;
    }
    .item_flex_2 img {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .item_about:nth-child(1) {
        display: none;
    }
    .item_about:nth-child(2) {
        width: 100%;
        /* The image used */
        background-image: url(/static/media/profil6.2d7dac7e.webp);
        /* Full height */
        height: 100%;
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 0px;
        margin-left: 0px;
        overflow: auto;
    }
    .flex_item_about {
        padding-left: 45px;
        margin-top: 250px !important;
    }
    #container_about {
        display: block;
    }
}

@media (max-width: 1300px) {
    .work span {
        display: block;
    }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    /* CSS */
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 993px to 1024px
  */

@media (min-width: 993px) and (max-width: 1280px) {
    .slide {
        margin-top: -100px !important;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 992px
  */

@media (min-width: 768px) and (max-width: 992px) {
    .slide {
        margin-top: -175px !important;
    }
    .slide img {
        width: 70%;
        height: 70%;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/


@media (min-width: 500px) and (max-width: 1230px) {
    .label_2 {
        width: 75%;
    }
    .label_1 {
        width: 25%;
    }
}

@media (min-width: 500px) and (max-width: 1024px) {
    .label_next:nth-child(1){
        width: 25% !important;
    }
    .label_next:nth-child(4) {
        width: 35% !important;
    }
}

@media (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
    #container-mobile {
        margin-top: 50px !important;
    }
    .slide {
        margin-top: -100px !important;
    }
    .slide img {
        width: 100%;
        height: 100%;
    }
    .label_1 {
        width: 25%;
    }
    .label_2 {
        width: 75%;
    }
    .label_next:nth-child(1) {
        width: 25% !important;
    }
    .details {
        font-size: 14px !important;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (max-width: 480px) {
    .project_name, .project_detail, .project_label {
        display: block;
    }
    .label_next, .label_1, .label_2 {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .label_next:nth-child(1) {
        width: 100% !important;
    }
    #container_main {
        position: relative;
        top: 15%;
        width: 100%;
    }
    .project_label:nth-child(1) {
        margin-bottom: 0px !important;
    }
    .details {
        padding: 0px !important;
        padding-top: 0px !important;
        padding-left: 45px !important;
        padding-right: 45px !important;
        font-size: 18px !important;
    }
    .details_label {
        font-size: 15px !important;
    }
    .container_1_bloc:before {
        content: '';
        position: absolute;
        top: 100;
        right: 0;
        border-top: 20px solid #000;
        border-right: 100vw solid transparent;
        width: 0;
    }
}

@media (max-width: 480px) {
    #work_name p {
        font-size: 20px !important;
    }
    #container_main {
        position: relative;
        top: 10%;
        width: 100%;
    }
}

@media (max-width: 360px) {
    .details{
        font-size: 16px !important;
    }
}


@media (min-width: 375px) and (max-width: 480px) {
    #container-mobile {
        margin-top: 30px !important;
    }
    .slide {
        margin-top: -175px !important;
    }
    .slide img {
        width: 100%;
        height: 60%;
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .item {
        font-size: 15px;
    }
    #container-mobile {
        margin-top: 50px !important;
    }
    .slide {
        margin-top: -175px !important;
    }
    .slide img {
        width: 100%;
        height: 40%;
    }
}

@media (min-width: 361px) and (max-width: 450px) {
    .compagny_work li {
        font-size: 0.7rem;
    }
    .flex_li li {
        font-size: 0.8rem;
    }
    .skill_about li span{
        font-size: 0.7rem;
    }

}

@media (max-width: 360px) {
    .compagny_work li {
        font-size: 0.6rem;
    }
    .contact_list li {
        font-size: 0.9rem;
    }
    .flex_li li {
        font-size: 0.7rem;
    }
    .skill_about li span{
        font-size: 0.6rem;
    }
}

@media (max-width: 320px) {
    #container_main {
        top: 5% !important;
    }
    .compagny_work li {
        font-size: 0.3em;
    }
    .contact_list li {
        font-size: 0.7rem;
    }
}

@media (max-width: 300px) {
    .item {
        font-size: 15px;
    }
    #container-mobile {
        margin-top: 50px !important;
    }
    .slide {
        margin-top: -175px !important;
    }
    .slide img {
        width: 100%;
        height: 40%;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #container-mobile {
        margin-top: 50px !important;
    }
    .slide {
        margin-top: -100px;
    }
    .slide img {
        width: 70%;
        height: 70%;
    }
}

@media (min-width: 800px) and (max-width: 830px) and (orientation: landscape) {
    .item {
        font-size: 20px;
        margin-top: 20px;
    }
    #work_name p {
        font-size: 18px !important;
    }
    #work_name p:nth-child(1) {
        margin-bottom: 10px;
    }
    #work_name {
        margin-top: 25px !important;
    }
    #container-mobile {
        margin-top: 20px !important;
    }
    .slide {
        margin-top: -80px !important;
    }
    .slide img {
        width: 45% !important;
        height: 100% !important;
    }
}

@media (min-width: 600px) and (max-width: 820px) and (orientation: landscape) {
    .item {
        font-size: 10px;
        margin-top: 20px;
    }
    #work_name p {
        font-size: 15px !important;
    }
    #work_name {
        margin-top: 15px !important;
    }
    #container-mobile {
        margin-top: 0 !important;
    }
    .slide {
        margin-top: -40px !important;
    }
    .slide img {
        width: 40%;
        height: 100%;
    }
}

@media (min-width: 700px) and (max-width: 730px) and (orientation: landscape) {
    .slide img {
        width: 60% !important;
    }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) {
    #container-mobile {
        margin-top: 50px !important;
    }
    .slide {
        margin-top: -100px !important;
    }
    .slide img {
        width: 70%;
        height: 70%;
    }
}

@media (min-width: 530px) and (max-width: 710px) and (orientation: landscape) {
    .item {
        font-size: 10px !important;
        margin-top: 15px !important;
    }
    #work_name p {
        font-size: 10px !important;
    }
    #work_name p:nth-child(1) {
        margin-bottom: 10px !important;
    }
    #work_name {
        margin-top: 10px !important;
    }
    #container-mobile {
        margin-top: 10px !important;
    }
    .slide {
        margin-top: -50px !important;
    }
    .slide img {
        width: 40%;
        height: 100%;
    }
}

@media (min-width: 500px) and (max-width: 750px) and (orientation: landscape) {
    .label_1 {
        width: 25%;
    }
    .label_2 {
        width: 75%;
    }
    .label_next:nth-child(1) {
        width: 25% !important;
    }
    .item {
        font-size: 15px;
    }
    .details {
        padding-top: 0px !important;
        font-size: 5px !important;
    }
    .details_label {
        font-size: 15px !important;
    }
    #container_main {
        position: relative;
        top: 10%;
        width: 100%;
    }
    .project_label:nth-child(1) {
        margin-bottom: 15px;
    }
}

@media (min-width: 751px) and (max-width: 1023px) and (max-height: 500px) and (orientation: landscape) {
    .label_1 {
        width: 25%;
    }
    .label_2 {
        width: 75%;
    }
    .label_next:nth-child(1) {
        width: 25% !important;
    }
    .item {
        font-size: 15px;
    }
    .details {
        padding-top: 0px !important;
        font-size: 15px !important;
    }
    .details_label {
        font-size: 25px !important;
    }
    #container_main {
        position: relative;
        top: 10%;
        width: 100%;
    }
    .project_label:nth-child(1) {
        margin-bottom: 0px;
    }
    p:nth-child(1) {
        margin-bottom: 0px;
    }
}

@media (min-width: 751px) and (max-width: 818px) and (orientation: landscape) {
    #container_main {
        position: relative;
        top: 7%;
        width: 100%;
    }
}

@media (min-width: 480px) and (max-width: 540px) {
    .details {
        font-size: 14px !important;
    }
}

/* TODO pas sure d'etre utilisé */

@media (min-width: 541px) and (max-width: 770px) {
    .details {
        font-size: 20px !important;
    }
}

@media (min-width: 541px) and (max-width: 770px) and (orientation: landscape) {
    .details {
        font-size: 15px !important;
    }
}

@media (max-width: 700px) and (orientation: landscape) {
    .details {
        font-size: 13px !important;
    }
    .details_label {
        font-size: 18px !important;
    }
    .item {
        font-size: 15px !important;
    }
    #container_main {
        position: relative;
        top: 10%;
        width: 100%;
    }
    .project_label:nth-child(1) {
        margin-bottom: 0px;
    }
}

@media (max-width: 660px) and (orientation: landscape) {
    .details {
        font-size: 12px !important;
    }
    .details_label {
        font-size: 15px !important;
    }
    .item {
        font-size: 15px !important;
    }
    #container_main {
        position: relative;
        top: 5%;
        width: 100%;
    }
    .project_label:nth-child(1) {
        margin-bottom: 0px;
    }
    p:nth-child(1) {
        margin-bottom: 0px;
    }
}

.MuiButton-label{
    color: #fff;
}
